import { createStore, useStore, createCollectionStore } from 'aeria-ui'

export const customer = createStore((context) => createCollectionStore({
  $id: 'customer',
  state: {},
  actions: () => ({
    viewOrders: async <T extends { _id: string }>(filters: T) => {
      const orderStore = useStore('order', context.manager)
      orderStore.filters.customer = filters._id

      await orderStore.$actions.filter()
      ROUTER.push({
        name: '/dashboard/order/',
        query: {
          'order.customer': filters._id
        }
      })
    }
  })
}, context))

