<script setup lang="ts">
import { CollectionItemWithId } from '@aeriajs/types'

type AuthorizationFields = keyof Pick<CollectionItemWithId<'integration'>, 'key' | 'key_alt' | 'extra'>

// Used for name different platform keys to avoid confusion
const keyLabels: { [key in CollectionItemWithId<'integration'>['platform']]?: { [key in AuthorizationFields]?: string } } = {
    kiwify: {
        key: 'client_secret',
        key_alt: 'client_id',
        extra: 'account_id'
    }
}

const integrationStore = useStore('integration')

const props = defineProps<{
  modelValue?: string,
  field: string
}>()

const model = ref(props.modelValue)
watch(() => props.modelValue, (value) => {
  model.value = value
})

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void
}>()

const onInput = (value: unknown) => {
    emit('update:modelValue', value as string)
}
</script>

<template>
    <aeria-input v-model="model" @input="onInput">
        {{ integrationStore.item.platform in keyLabels ? keyLabels[integrationStore.item.platform][field] : '' }}
    </aeria-input>
</template>
