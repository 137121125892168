import { createStore, createCollectionStore } from 'aeria-ui'
import { reactive } from 'vue'

export const shippingTable = createStore((context) => {
  const state = reactive({
    simulation: {
      zipcode: '',
      weight: 0
    }
  })

  return createCollectionStore({
    $id: 'shippingTable',
    state,
    actions: (_, actions) => ({
      simulate() {
        return actions.custom('simulate', state.simulation)
      },

      //     load({ filters }: any) {
      //       return this.customEffect(
      //         'load', { filters: { _id: filters._id } },
      //         this.insertItem
      //       )
      //     },

      //     unload({ filters }: any) {
      //       return this.customEffect(
      //         'unload', { filters: { _id: filters._id } },
      //         this.insertItem
      //       )
      //     },

      //     async loadAll() {
      //       this.custom('loadAll')
      //       return this.filter()
      //     },
      //     async unloadAll() {
      //       this.custom('unloadAll')
      //       return this.filter()
      //     }

    })
  }, context)
})
