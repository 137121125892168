<script setup lang="ts">
import { viewIntegration } from '../../../helpers';
import PlanSection from './plan-section.vue'

definePage({
  meta: {
    title: 'Produto',
    icon: 'pill'
  }
})

const router = useRouter()

const productStore = useStore('product')
const productPlanStore = useStore('productPlan')
const productTypeStore = useStore('productType')

const panelVisible = ref(false)
const productType = ref(productStore.item.product_type)

type Section =
  | 'all'
  | 'plan'
  | 'packaging'
  | 'productType'

const section = computed(() => router.currentRoute.value.query.section as Section || 'all')
const setting = ref<'plan' | 'product'>('plan')

const configProduct = async (product: any) => {
  console.log(product);
  
  productStore.$actions.setItem({
    ...product,
    product_type: product.product_type?._id
  })
  await productPlanStore.$actions.getAll({
    filters: {
      product: product._id
    }
  })

  productPlanStore.items = productPlanStore.items.map(item => ({
    ...item,
    product_type: item.product_type?._id
  }))

  console.log(productPlanStore.items)
  
  panelVisible.value = true
}

const insert = async (product: any) => {
  if (setting.value === 'plan') {
    await productStore.$actions.insert({
      what: {
        _id: product._id,
        was_configured: true
      }
    })

    for( const plan of productPlanStore.items ) {
      await productPlanStore.$actions.insert({
        what: {
          ...productPlanStore.$actions.select([
            '_id',
            'locked',
            'quantity',
          ], plan),
          product_type: (plan.product_type?.length ?? 0) > 0 ? plan.product_type : null,
          locked: true
        }
      })
    }
  } else {
    await productStore.$actions.insert({
      what: {
        _id: product._id,
        was_configured: true,
        ...productStore.$actions.select([
            'locked',
        ]),
        product_type: (productStore.item.product_type?.length ?? 0) > 0 ? productStore.item.product_type : null,
      }
    })
  }

  panelVisible.value = false
}

const setDefaultType = async (type: any) => {
  type.is_default = !type.is_default
  await productTypeStore.$actions.insert({
    what: productTypeStore.$actions.select([
      '_id',
      'is_default'
    ], type)
  })

  if( type.is_default ) {
    productTypeStore.$actions.getAll({
      limit: 100
    })
  }
}

watch(section, async () => {
  if( section.value === 'all' ) {
    productTypeStore.$actions.getAll({
      limit: 100
    })
  }
}, { immediate: true })

const handleProductUiEvent = (event: any) => {
  if( event.name === 'configure' ) {
    configProduct(event.params)
  }
}
</script>

<template>
  <aeria-crud
    v-if="section === 'all'"
    collection="product"
    @ui-event="handleProductUiEvent"
  >
    <template #row-name="{ row, column }">
      <div class="tw-font-[400]">{{ row[column] }}</div>
      <div class="tw-opacity-60">{{ row.code }}</div>
    </template>

    <template #row-integration="{ row, column }">
      <span class="
        tw-flex
        tw-items-center
        tw-gap-1
      ">
        <extra-icon v-clickable @click="() => viewIntegration(row.integration)" :name="row.integration?.platform"></extra-icon>
        <div>{{ row[column]?.name }}</div>
      </span>
    </template>
  </aeria-crud>

  <plan-section v-if="section === 'plan'"></plan-section>
<!--   <aeria-crud v-if="section === 'plan'" collection="productPlan"></aeria-crud> -->
  <aeria-crud v-if="section === 'packaging'" collection="packaging"></aeria-crud>

  <aeria-crud v-if="section === 'productType'" collection="productType">
    <template #row-is_default="{ row, column }">
      <aeria-switch
        v-model="row[column]"
        v-bind="{
          property: {
            type: 'boolean'
          }
        }"

        @change="setDefaultType(row)"
      >
      </aeria-switch>
    </template>
  </aeria-crud>

  <aeria-prompt
    float
    close-hint
    v-model="panelVisible"
    :options="{
      cancel: {
        title: 'Cancelar',
        click: () => { panelVisible = false },
        variant: 'danger'
      },
      save: {
        title: 'Salvar',
        click: () => { insert(productStore.item) }
      }
    }"
    @overlay-click="panelVisible = false"
  >
    <template #header>
      <div>
        Configurando&nbsp;<strong>{{ productStore.item.name }}</strong>
      </div>
    </template>
    <div class="tw-mb-4">
      <div>Configurar</div>
      <aeria-select v-model="setting">
        <option value="product">Produto</option>
        <option value="plan">Plano</option>
      </aeria-select>
    </div>
    
    <div v-if="setting === 'plan'">
      <div>
        <div>Tipo</div>
        <aeria-select
          v-model="productType"
          @change="productPlanStore.items.forEach((plan) => { plan.product_type = productType })"
        >
          <option
            v-for="type in productTypeStore.items"
            :key="type._id"
            :value="type._id"
          >
            {{ type.name }}
          </option>
        </aeria-select>
      </div>

      <div class="
        tw-flex
        tw-flex-col
        tw-gap-4
        tw-mt-8
      ">
        <div
          v-for="plan in productPlanStore.items"
          :key="plan._id"
          class="
            tw-flex
            tw-items-center
            tw-justify-between
            tw-gap-6
          "
        >
          <div class="tw-mr-auto tw-w-1/2">
            <div class="tw-font-semibold">{{ plan.name }}</div>
            <div class="tw-opacity-80 tw-text-sm">{{ plan.code }}</div>
          </div>
          <aeria-info>
            <template #text>
              Bloqueia mudanças futuras provenientes da plataforma de pagamento.
            </template>
            <aeria-switch
              v-model="plan.locked"
              v-bind="{
                property: {
                  description: 'Trancar',
                  type: 'boolean'
                }
              }"
            ></aeria-switch>
          </aeria-info>
          <div class="
            tw-flex
            tw-flex-col
            tw-gap-2
            tw-w-[10rem]
          ">
            <aeria-select
              v-model="plan.product_type"
              v-bind="{
                property: {
                  product_type: {
                    type: 'string'
                  }
                }
              }"
            >
              <option
                v-for="type in productTypeStore.items"
                :key="type._id"
                :value="type._id"
              >
                {{ type.name }}
              </option>
            </aeria-select>
            <aeria-input
              bordered
              v-model="plan.quantity"
              v-bind="{
                property: {
                  type: 'number',
                  s$placeholder: 'Quantidade'
                }
              }"
            ></aeria-input>
          </div>
        </div>
      </div>
    </div>

    <div v-if="setting === 'product'">
      <div class="
        tw-flex
        tw-flex-col
        tw-gap-4
        tw-mt-8
      ">
        <div
          class="
            tw-flex
            tw-items-center
            tw-justify-between
            tw-gap-6
          "
        >
          <div class="tw-mr-auto tw-w-1/2">
            <div class="tw-font-semibold">{{ productStore.item.name }}</div>
            <div class="tw-opacity-80 tw-text-sm">{{ productStore.item.code }}</div>
          </div>

          <aeria-info>
            <template #text>
              Bloqueia mudanças futuras provenientes da plataforma de pagamento.
            </template>
            <aeria-switch
              v-model="productStore.item.locked"
              v-bind="{
                property: {
                  description: 'Trancar',
                  type: 'boolean'
                }
              }"
            ></aeria-switch>
          </aeria-info>

          <div class="
            tw-flex
            tw-flex-col
            tw-gap-2
            tw-w-[10rem]
          ">
            <aeria-select
              v-model="productStore.item.product_type"
              v-bind="{
                property: {
                  product_type: {
                    type: 'string'
                  }
                }
              }"
            >
              <option
                v-for="type in productTypeStore.items"
                :key="type._id"
                :value="type._id"
              >
                {{ type.name }}
              </option>
            </aeria-select>
          </div>
        </div>
      </div>
    </div>
  </aeria-prompt>
</template>

