import { createStore, useStore, createCollectionStore } from 'aeria-ui'

export const product = createStore((context) => createCollectionStore({
  $id: 'product',
  actions: (_state, _actions) => ({
    async viewOrders<T extends { _id: string }>(filters: T) {
      const orderStore = useStore('order', context.manager)
      orderStore.filters.products = filters

      await orderStore.$actions.filter()
      ROUTER.push({
        name: '/dashboard/order/',
        query: {
          'order.products[]': filters._id
        }
      })
    }
  })
}, context))

