import { useApp, useStore, defineOptions, registerStores } from 'aeria-ui'

export const daysAgo = function(target: Date, days: number) {
  const d = new Date()
  d.setDate(target.getDate() - days)
  return d
}

import '@aeria-ui/ui/style.css'
import 'aeria-app-layout/style.css'
import './style/main.css'
import './style/main.less'

import Main from './main.vue'
import aeriaPtbr from '@aeria-ui/i18n-pt'
import { routes } from './routes'

import { ptBr } from '../i18n/index.js'
import * as stores from './stores'

import EmptyComponent from './components/empty-component.vue'
import PlatformSelect from './components/platform-select.vue'
import AuthorizationLabel from './components/authorization-label.vue'


const options = defineOptions({
  routes,
  component: Main,
  setup: ({ context }) => {
    registerStores(stores, context)
  },
  i18n: {
    current: 'pt_BR',
    locales: {
      pt_BR: [
        aeriaPtbr,
        ptBr,
      ],
    }
  },
  menuSchema: [
    {
      children: [
        '/dashboard/',
        {
          path: '/dashboard/order',
          badge: async () => {
            const { result } = await useStore('order').$functions.count({
              filters: {
                status: 'paid',
                shipping_address: {
                  $ne: null
                },
                shipment: null,
                date_updated: {
                  $lte: daysAgo(new Date, 1),
                  $gte: daysAgo(new Date, 30)
                },
              }
            })
            return result 
          }
        },
        '/dashboard/product',
        {
          path: '/dashboard/customer',
          roles: [
            'root',
            'logistic',
            'support',
            'manager'
          ]
        }
      ]
    },
    {
      roles: [
        'root',
        'logistic',
        'manager'
      ],
      children: [
        {
          path: '/dashboard/shipment/',
          badge: async () => {
            const { result } = await useStore('shipment').$functions.count({
              filters: {
                status: {
                  $in: [
                    'pending',
                    'failed'
                  ]
                }
              }
            })
            return result
          }
        },
        '/dashboard/manual-posting',
      ]
    },
    {
      roles: ['customerSupport', 'logistic', 'manager', 'root', 'support'],
      meta: {
        title: 'Configurações',
        icon: 'gear',
      },
      collapsed: true,
      children: [
        '/dashboard/business',
        '/dashboard/shippingTable/',
        '/dashboard/shippingService',
        '/dashboard/shippingProfile',
        {
          path: '/dashboard/integration/',
          badge: async () => {
            const { result } = await useStore('integration').$functions.count({
              filters: {
                fail_count: -1
              }
            })
            return result 
          },
        },
      ]
    },
    {
      roles: [
        'root',
        'manager'
      ],
      children: [
        '/dashboard/stats',
        '/dashboard/user',
        '/dashboard/log',
        '/dashboard/organization'
      ]
    }
  ],
})

useApp(options).then(({ app, mount }) => {
  app.provide('emptyComponent', EmptyComponent)
  app.provide('propertyComponents', {
    PlatformSelect,
    AuthorizationLabel
  })
  mount()
})

